import React from "react"

const TagList = ({ tags, onDelete, size }) => {
  return (
    <div className="content">
      {tags.length === 0 && <span className="has-text-weight-light">(no tags)</span>}
      <div className="field is-grouped is-grouped-multiline">
        {tags.map((t) => {
          return (
            <div key={t} className="control">
              <span data-tag={t} className="tags has-addons">
                <span className="tag is-grey has-text-light is-size-7">
                  {t}
                  {onDelete && (
                    <button type="button" className="delete is-small" aria-label={`delete ${t}`} onClick={() => onDelete(null, t)}></button>
                  )}
                </span>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TagList
