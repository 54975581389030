import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import jiggswapApi from "../../api/jiggswap"
import TagList from "../../components/puzzles/TagList"
import { Helmet } from "react-helmet-async"

const PuzzlesView = () => {
  const [puzzle, setPuzzle] = useState({})

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const puzzleId = urlParams.get("p")

    async function fetchPuzzles() {
      let response = await jiggswapApi.get("/puzzles/" + puzzleId)

      setPuzzle(response.data)
      setLoaded(true)
    }

    fetchPuzzles()
    // eslint-disable-next-line
  }, [false])

  return (
    <>
      <Helmet>
        <meta property="og:site_name" content="JiggSwap" />
        <meta property="og:title" content={"View Puzzle - JiggSwap"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={"https://www.jiggswap.com/view?p=" + puzzle.puzzleId} />
        <meta property="og:image" content={"https://www.jiggswap.com/logo.png"} />
        <meta property="og:description" content={"View this puzzle on JiggSwap."} />
        <title>{"View Puzzle - JiggSwap"}</title>
      </Helmet>
      <div className="section">
        <div className="columns">
          <div className="column">
            <figure className="image is-4by3">
              <img src={puzzle.imageCdnUrl || "https://cdn.jiggswap.com/assets/placeholder.png"} alt={puzzle.title} />
            </figure>
          </div>

          <div className="column">
            <article className="message is-primary">
              <div className="message-header">Puzzle Details</div>
              <div className="message-body">
                <div className="columns is-mobile">
                  <div className="column is-one-third has-text-weight-bold">Title</div>
                  <div className="column" style={{ wordBreak: "break-word" }}>
                    {puzzle.title}
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column is-one-third has-text-weight-bold">Owned By</div>
                  <div className="column">{puzzle.ownerUsername}</div>
                </div>
                <div className="columns is-mobile">
                  <div className="column is-one-third has-text-weight-bold">Brand</div>
                  <div className="column">{puzzle.brand}</div>
                </div>
                <div className="columns is-mobile">
                  <div className="column is-one-third has-text-weight-bold"># Pieces</div>
                  <div className="column">
                    {puzzle.numPieces}{" "}
                    <span className="is-size-6">
                      {" "}
                      (<span className="has-text-weight-bold">{puzzle.numPiecesMissing}</span> missing).
                    </span>
                  </div>
                </div>
                {puzzle.additionalNotes && (
                  <div className="columns is-mobile">
                    <div className="column is-one-third has-text-weight-bold">Notes</div>
                    <div className="column">{puzzle.additionalNotes}</div>
                  </div>
                )}
                {puzzle.tags && (
                  <div className="columns is-mobile">
                    <div className="column is-one-third has-text-weight-bold">Tags</div>
                    <div className="column">
                      <TagList tags={puzzle.tags} />
                    </div>
                  </div>
                )}
                <hr />

                <div className="has-text-centered">
                  {loaded && (
                    <div>
                      <div>
                        {!puzzle.isOwner && (
                          <>
                            {puzzle.isInTrade && (
                              <>
                                <div>This puzzle is currently in a pending trade.</div>
                                <div className="button is-primary" disabled>
                                  I want this! Start a trade.
                                </div>
                              </>
                            )}
                            {!puzzle.isInTrade && (
                              <Link to={`/trade/start?user=${puzzle.ownerUsername}&p=${puzzle.puzzleId}`} className="button is-primary">
                                I want this! Start a trade.
                              </Link>
                            )}
                          </>
                        )}

                        {puzzle.isOwner && (
                          <Link to={`/puzzles/update`} state={{ puzzle: puzzle }} className="button is-primary">
                            Update Puzzle
                          </Link>
                        )}
                      </div>
                      <hr />
                      <div>
                        <div>
                          Part of <span className="has-text-weight-bold">{puzzle.isOwner ? "your" : puzzle.ownerUsername + ""}</span>
                          {!puzzle.isOwner && <>'s</>} collection.
                        </div>

                        <div>
                          <Link to={"/user?username=" + puzzle.ownerUsername} className="button is-secondary">
                            View {puzzle.isOwner ? "My" : "Their"} Collection
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default PuzzlesView
